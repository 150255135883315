<template>
    
    <div class="h-auto p-2">

        <div class="h-auto w-full rounded-lg module p-2 flex flex-col">

            <div class="h-auto bg-filters rounded-lg p-2 flex flex-col">

                <top-scores :mobile="true"/>

            </div>

            <div class="bg-filters rounded-lg mt-2 flex flex-col p-2">
                <div class="h-auto py-1 mt-2 flex flex-row justify-start items-center">
                    <span class="text-font-gray font-semibold text-xs">Last posts</span>
                </div>
                <div class="h-32">
                    <last-posts view="mobile" class="flex-1 min-h-0"/>
                </div>
                <div class="h-10 rounded-lg bg-body mt-2 cursor-pointer flex flex-row justify-center items-center" @click="goSmart()">
                    <img class="h-6 text-white" src="/images/smarp.png">
                </div>
            </div>

            <div class="h-72 bg-filters rounded-lg mt-2 flex flex-col p-2">
                <div class="h-auto py-1 mt-2 flex flex-row justify-start items-center">
                    <span class="text-font-gray font-semibold text-xs">Last updates</span>
                </div>
                <last-updates view="mobile" class="flex-1 min-h-0"/>
            </div>

            <div class="pill-aux mt-2" @click="$router.push({name: 'Tipsanddocs'})">
                <i class="mdi mdi-file-document-outline text-xxs"></i> <span class="text-xxs">TIPS & DOCS</span>
            </div>

        </div>

    </div>

</template>

<script>

const LastUpdates = () => import('@/components/Globals/PersonalDashboard/LastUpdates');
import topScores from '../../components/Desktop/PersonalDashboard/topScores.vue';
import lastPosts from '../../components/Desktop/PersonalDashboard/LastPosts.vue';

export default {
    components: {
        LastUpdates,
        topScores,
        lastPosts
    },
    data() {
        return {
            networks: false,
            loading_socialmedia: false,
            loading_posts: false,
            post: false
        }
    },
    methods: {
        async loadNetworks() {
            this.loading_socialmedia = true;
            const { data } = await this.axios.get(process.env.VUE_APP_SOCIAL_URL + 'social/posts-count')
                .finally(() => this.loading_socialmedia = false);
            this.networks = data;
        },
        loadPosts() {
            this.loading_posts = true
            this.axios.get(process.env.VUE_APP_SOCIAL_URL + 'social/posts').then(response => {
                this.loading_posts = false
                this.post = response.data.posts[0]
            })
        },
        color(social) {
            switch (social) {
                case 'instagram':
                    return 'danger'
                    break;
                default:
                    return 'blue'
                    break;
            }
        },
        load() {
            this.loadNetworks();
            this.loadPosts();
        },
        colorBySocialNetwork(social) { return this.$colorSocialNetwork(social) },
        goSmart(){
            window.open('https://ey.smarpshare.com/#/', '_blank').focus();
        }
    },
    computed: {
        imageRoute() { return this.$imageRoute }
    },
    mounted() { this.load() }
}
</script>